import http from '@/utils/request'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "api/invest"

// get请求
export function getListAPI(params){
    return http.get(`${resquest}/user_login`,params)
}


// post请求
//皖云投首页接口
export function appindex(params){
    return http.post(`${resquest}/appindex`,params)
}
//皖云投动态接口
export function newsheader(params){
    return http.post(`${resquest}/newsheader`,params)
}
//皖云投动态列表接口
export function newsLists(params){
    return http.post(`${resquest}/index`,params)
}
//皖云投动态列表详情页接口
export function newdetail(params){
    return http.post(`${resquest}/newdetail`,params)
}
//全景vr+轮播图+规划图接口
export function video_img_vrs(params){
    return http.post(`${resquest}/video_img_vr`,params)
}   
//投资园区详情页接口
export function gardendetail(params,url){
    return http.post(`${resquest}/${url}`,params)
}
//地图首页接口
export function region_info(params){
    return http.post(`${resquest}/region_info`,params)
}
//投资板块首页地图接口
export function invest_cate_img(params){
    return http.post(`${resquest}/invest_cate_img`,params)
}
//8大分类列表接口
// url是请求路径
export function project_list(params,url){
    return http.post(`${resquest}/${url}`,params)
}
//地图地块接口
export function mapimg_list(params){
    return http.post(`${resquest}/land_mapimg_list`,params)
}
//地图地块列表接口
export function land_map_list(params){
    return http.post(`${resquest}/land_map_list`,params)
}
//印象区请介绍详情
export function impression_detail(params){
    return http.post(`${resquest}/impression_detail`,params)
}
//印象风貌图集视频列表接口
export function indexfeature(params){
    return http.post(`${resquest}/indexfeature`,params)
}
//风貌视频详情接口
export function featuresimg(params){
    return http.post(`${resquest}/featuresimg`,params)
}
//手册接口
export function indexebook(params){
    return http.post(`${resquest}/indexebook`,params)
}
//投资政策分类接口
export function servervice(params){
    return http.post(`${resquest}/servervice`,params)
}
//投资政策分类列表接口
export function server_cates_one(params){
    return http.post(`${resquest}/server_cates_one`,params)
}
//投资政策分类列表详情接口
export function server_news_info(params){
    return http.post(`${resquest}/server_news_info`,params)
}
//产业链详情接口
export function industrial_detail(params){
    return http.post(`${resquest}/industrial_detail`,params)
}
//产业链推荐项目列表接口
export function get_industry_list(params){
    return http.post(`${resquest}/get_industry_list`,params)
}





// put 请求
export function putSomeAPI(params){
    return http.put(`${resquest}/putSome.json`,params)
}
// delete 请求
export function deleteListAPI(params){
    return http.delete(`${resquest}/deleteList.json`,params)
}